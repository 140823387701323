import Server from "../server/Server";
import './list.css';
import remove from '../../assets/remove.png'

const List = ({data, onRemove}) => {
  const server = new Server();

  const onRemoveText = (e) => {
    server.removeText(e.target.getAttribute('data-id')).then(() => onRemove());
  };
  
  const rows = data.map((item) => (
    <tr key={item.id}>
      <td>{item.id}</td>
      <td>{item.text}</td>
      <td data-id={item.id} onClick={onRemoveText}><img data-id={item.id} src={remove}/></td>
    </tr>
  ));

  return (
    <table>
      <tbody>{rows}</tbody>
    </table>
  );
}

export default List;