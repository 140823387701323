import Server from "../server/Server";

const Add = ({onAdd}) => {
    const server = new Server();

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
      
        server.addText(formData).then(() => onAdd());
      
        e.target.reset();
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="text" name="info" />
            <input type="submit" />
        </form>
    );
};

export default Add;