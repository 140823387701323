import List from "../list/list";
import Add from "../add/add";
import { Component } from "react";
import Server from "../server/Server";

class App extends Component {
  state = {
    data: []
  };

  server = new Server();

  getData = () => {
    this.server.getTexts().then((data) => {
      this.setState({data});
    });
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const { data } = this.state;
    return (
      <>
        <List data={data} onRemove={this.getData}/>
        <Add onAdd={this.getData} />
      </>
    );
  }
}

export default App;