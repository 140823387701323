class Server {
  getTexts = () => {
    return fetch('http://test/get.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        return [];
      });
  };

  addText = (addInfo) => {
      return fetch('http://test/add.php', {
          method: 'POST',
          body: addInfo
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        return null;
      });
  };

  removeText = (addInfo) => {
    return fetch(`http://test/remove.php?id=${addInfo}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
      return null;
    });
};
}

export default Server;